.toggle input {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
  z-index: -1;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: -5px;
  right: 0;
  bottom: 0;
  transition: 0.4s;
  border-radius: 24px;
  border: 2px solid rgba(3, 32, 32, 0.15);
  width: 48px;
  height: 28px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 0; /* Adjusted for proper positioning */
  bottom: 2px;
  transition: 0.4s;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(3, 32, 32, 0.15);
  background-image: url("../../../images/close-toggle.png"); /* Default image */
  background-size: 11px 8px; /* Ensure the image covers the dimensions */
  background-repeat: no-repeat; /* Prevent repetition */
  background-position: center; /* Center the image */
}

input:checked + .slider {
  border: 2px solid #ff6b00;
}

input:checked + .slider:before {
  background-color: #ff6b00; /* Keep the orange background */
  background-image: url("../../../images/open-toggle.png");
  background-size: 11px 8px;
  background-position: center;
  transition: 0.4s;
  transform: translateX(26px);
}

input:not(:checked) + .slider:before {
  left: 3px; /* Position adjustment for the "close" image */
}

input:checked + .slider:before {
  left: auto; /* Reset left */
  right: 3px; /* Position adjustment for the "open" image */
  transform: translateX(0); /* Reset transform */
}
