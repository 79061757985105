.inputFields{
  width:200px;
  margin-left:5px;
}
.saveBtn{
  margin-top: 20px;
  margin-left:5px;
}

.invite-details-content .first-row,.invite-details-content .second-row, .invite-details-content .third-row {
  display: flex;
  gap: 16px;
  margin:0;
  padding: 10px 0;
}

.invite-details-content .first-row .rows-content input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #F6F7FC inset !important;
}
.invite-details-content .first-row .rows-content, .invite-details-content .second-row .rows-content, .invite-details-content .third-row .rows-content {
  width: 100%;
}

.invite-details-content .rows-content label {
  font-family: 'Satoshi Bold'; 
  color: #032020;
  font-size: 16px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}

.invite-details-content .rows-content input.form-control, .invite-details-content .rows-content select.form-control {
    padding: 8px 16px;  
}

.invite-details-content .rows-content input, .invite-details-content .rows-content select {
  font-family: 'Satoshi Medium';
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #032020;
  background-color: #F6F7FC;
}

.modal-invite-save-button{
  margin:0;
  background: #004747;
  color: #fff;
}

.project-creation-container {
  position: relative;
}

.blur {
  filter: blur(1px);
}

.loader-overlay {
  position: relative;
  top: 300px;
  left: 100%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.rows-content.error input[type="text"],
.rows-content.error input[type="email"],
.rows-content.error input[type="password"],
.rows-content.error select {
  border: 1px solid  #C70039;
}

.rows-content.error .select{
  border: 1px solid  #C70039;
}

.rows-content.error .date{
  border: 1px solid  #C70039;
}
.rows-content.error .date .error-message {
    position: relative;
    left:-17px;
}
.error-message {
  color:  #C70039;
  font-size: 12px;
  padding-top: 6px;
  display: block;
  font-weight: 700;

}
@media only screen and (max-width: 768px) {
  .invite-details-content .first-row, .invite-details-content .second-row, .invite-details-content .third-row{
    flex-direction: column;
  }
}

