body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.sidebar-content {
    width: 10%;
    /* padding: 30px 24px; */
}

.sidebar-content .sidebar ul {
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 0;
}

.sidebar-content .sidebar ul li {
    display: flex;
    gap: 16px;
    right: 10px;
    position: relative;
}

.sidebar-content .sidebar ul li span {
    font-family: 'Satoshi Medium';
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    padding-top: 4px;
    opacity: 0.7;
}

.projecticon {
    opacity: 0.3;
}

.sidebar-content .sidebar ul li a {
    color: #032020;
    opacity: 0.6;
    text-decoration-line: none;
}

.sidebar-content .sidebar ul li.activeuser a {
    color: #ff6b00;
}

.sidebar-content .sidebar ul li.active a {
    color: #021a1a;
    opacity: 100%;
}

.sidebar-content .sidebar ul li.active span {
    font-weight: bold;
}

.sidebar-content .sidebar ul li.active .projecticon {
    opacity: 1;
}

.menus {
    position: relative;
    margin-top: 147px;
}

.child1 {
    position: fixed;
    left: 0;
    padding: 0 24px;
}

.child2 {
    bottom: 0;
    position: fixed;
    left: 0;
    padding: 0 24px;
    margin-bottom: 40px;
}

.sidebar-content .sidebar ul li img {
    width: 24px;
    height: 25px;
}
.toggle-btn{
    display: none;
}

@media (max-width: 768px) {
    .sidebar {
        width: 100%;
    }

    .menus {
        display: none;
        margin-top:0;
    }

    .menus.visible {
        display: block;
        background-color: #f6f7fc;
        padding: 12px 20px;
        z-index: 9999;
        width: 100vw;
        left: 0;
        right: 0;
        
    }

    .toggle-btn {
        display: block;
        background: none;
        border: none;
        font-size: 24px;
        margin: 10px;
        width:10%;
    }
    .child1 {
        position: relative;
    }
    .child2 {
        position: relative;
        margin-top: 24px;
    }
    .sidebar-content{
        width:100%;
    }

}

