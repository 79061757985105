.jsonlistDetailsContent {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  gap: 12px;
}

.contentOptions {
  display: flex;
  gap: 10px;
}

.searchOptionSearchfilter {
  display: flex;
  gap: 6px;
  border: 1px solid #d4d4d4;
  padding: 5px 5px 3px 15px;
  height: 40px !important;
  width: 100%;
  border-radius: 25px;
  background-color: #f6f7fc;
}

.searchInput {
  display: flex;
  gap: 20px;
}
.searchInput img {
  width: 20px;
  height: 20px;
  margin-top: 6px;
}

.searchInput input {
  border: none;
  background: none;
  width: 122px;
  font-family: "Satoshi Medium";
  font-size: 14px;
  font-weight: 500;
  line-height: 18.9px;
  letter-spacing: 0.0063em;
  text-align: left;
  color: #b4b4b4 !important;
}
.searchInput input[type="text"]:focus {
  border: none;
  background-color: none;
  outline: 0;
}

.filterBtn {
  padding: 7px 25px;
  border: 1px solid rgba(3, 32, 32, 0.15);
  border-radius: 25px;
  color: #032020;
  opacity: 60%;
  background-color: #fff;
  white-space: nowrap;
}
.activeBtn {
  border: 1px solid #45c0b2;
  color: #45c0b2;
  border-radius: 25px;
  padding: 7px 25px;
  background-color: #fff;
  white-space: nowrap;
}

.filtersElement {
  width: 150px;
}

.filtersType {
  width: 83px;
}

.filtersLocation {
  width: 120px;
}

.jsonFilterBtn {
  background-color: #45c0b2;
  padding: 7px 25px;
  border: 1px solid rgba(3, 32, 32, 0.15);
  border-radius: 25px;
  white-space: nowrap;
  color: #fff;
  border: none;
  height: 40px;
}

.btnElement span,
.btnType span,
.btnLocation span,
.jsonFilterBtn span,
.jsonExportBtn span {
  font-family: "Satoshi Medium";
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.totalResult h6 {
  font-family: "Satoshi Medium";
  font-weight: 500;
  font-size: 14px;
  line-height: 16.8px;
  margin-bottom: 0;
}

.jsonExportBtn {
  background-color: #cf8e00;
  padding: 7px 20px;
  border-radius: 25px;
  white-space: nowrap;
  color: #fff;
  white-space: nowrap;
  height: 40px;
}
.jsonExportBtn button a {
  font-family: "Satoshi Medium";
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #fff !important;
}

.jsonExportBtn button {
  background: none;
  border: none;
}

.jsonExportBtn a {
  color: #fff;
  text-decoration: none;
}

.rightButtons {
  display: flex;
  align-items: center;
  gap: 32px;
}

.mainColumnSelection {
  right: 4px;
  z-index: 1000;
}
.switch {
  cursor: pointer;
}
@media screen and (max-width: 1024px) and (max-height: 768px) {
  .jsonlistDetailsContent {
    overflow-x: scroll;
  }
}

@media (max-width: 1600px) {
  .jsonlistDetailsContent {
    overflow-x: scroll;
  }
}
@media only screen and (max-width: 768px) {
}

.switch {
  display: inline-block;
  position: relative;
  width: 48px;
  height: 28px;
}
