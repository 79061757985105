.list {
  padding: 16px 24px;
}
.recordRow {
  background: #f2f2f2;
  position: relative;
  border-bottom: 1px solid rgba(3, 32, 32, 0.15);
}

.projectlistDetailsTableContent .tableContent .list table tbody tr {
  border-bottom: 1px solid rgba(3, 32, 32, 0.15);
  cursor: pointer;
}
.projectlistDetailsTableContent .tableContent .list table tbody tr:hover {
  background-color: #d3d3d3;
}

.projectlistDetailsTableContent .tableContent .list table thead tr th {
  font-family: "Satoshi Medium";
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  color: #032020;
  opacity: 60%;
  padding: 2px 18px;
  height: 39px;
  white-space: nowrap;
}

.projectlistDetailsTableContent .tableContent .list table tbody tr td {
  font-family: "Satoshi Medium";
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  color: #032020;
  padding: 2px 18px;
  height: 39px;
}

.jsonMainColumnSelection {
  right: -13px;
  top: -2px;
  padding: 2px 20px;
}

.selectionButton {
  cursor: pointer;
  border: none;
  background: none;
}

.forNameRowOpac {
  width: 25%;
  text-align: left;
  padding: 2px 20px;
}
.boldText{
  font-weight: bold!important;
}
.elementRow {
  width: 20%;
  text-align: left;
}
.typeRow {
  width: 10%;
  text-align: left;
}
.locationRow {
  width: 10%;
  text-align: left;
}
.viewRow {
  width: 10%;
  text-align: center;
}
.viewRow a {
  display: inline-block;
  border-radius: 6px;
  background: #f6f7fc; /* Default background color */
  padding: 5px;
  transition: background-color 0.3s ease;
}

.viewRow a:hover {
  background-color: #032020; /* Change background to green when clicked */
  outline: none; /* Remove the default focus outline */
}

/* Default image - shown initially */
.viewRow a img:first-of-type {
  display: block;
  transition: opacity 0.3s ease; /* Smooth transition */
}

/* Active image - hidden initially */
.viewRow a img:last-of-type {
  display: none;
  transition: opacity 0.3s ease; /* Smooth transition */
}

/* Toggle visibility when the link is active */
.viewRow a:hover img:first-of-type {
  display: none;
}

.viewRow a:hover img:last-of-type {
  display: block;
}

.lengthRow {
  width: 10%;
  text-align: right;
}
.areaRow {
  width: 5%;
  text-align: right;
}
.quantityRow {
  width: 5%;
  text-align: right;
}
.lengthTotalRow {
  width: 10%;
  text-align: right;
}
.grossRow {
  width: 10%;
  text-align: right;
}
.netRow {
  width: 10%;
  text-align: right;
}
@media screen and (max-width: 1024px) and (max-height: 768px) {
  .list {
    overflow-x: scroll;
  }
}

@media (max-width: 1600px) {
  .projectlistDetailsTableContent .tableContent {
    overflow-x: scroll;
  }
}
.showTotalRow {
  background-color:  #F2F2F2;
}
.showTotalItem {
  text-align: right;
}
