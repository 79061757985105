/* List Project */

.pageHeading {
  margin-bottom: 0;
}

.projectlist-record,
.userlist-record,
.organizationlist-record,
.quotelist-record, .jsonlist-record {
  background-color: white;
  padding: 30px 0;
  border-radius: 10px;
}

.projectlist-record .projectlist-details .form-head,
.userlist-record .userlist-details .form-head,
.organizationlist-record .organizationlist-details .form-head,
.quotelist-record .quotelist-details .form-head {
  padding: 0 24px;
}

.form-head.list-project,
.form-head.list-user,
.form-head.list-org,
.form-head.list-quote {
  display: flex;
  justify-content: space-between;
}

.form-head.list-project,
.form-head.list-user,
.form-head.list-quote {
  border-bottom: 1px solid #032020;
}

.projectlist-details .form-head.list-project h2,
.userlist-details .form-head.list-user h2,
.organizationlist-details .form-head.list-org h2,
.quotelist-details .form-head.list-quote h2 {
  font-family: "Satoshi Medium";
  font-size: 32px;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  color: #302030;
  padding-bottom: 16px;
}

.projectlist-details-content,
.userlist-details-content,
.organizationlist-details-content,
.quotelist-details-content {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  gap: 12px;
}

.projectlist-details-content .content-options,
.userlist-details-content .content-options,
.organizationlist-details-content .content-options,
.quotelist-details-content .content-options {
  display: flex;
  gap: 10px;
}

.projectlist-details-content .content-options .content-button,
.userlist-details-content .content-options .content-button,
.organizationlist-details-content .content-options .content-button,
.quotelist-details-content .content-options .content-button {
  display: flex;
  gap: 6px;
  padding: 6px 24px;
  border: 1px solid rgba(3, 32, 32, 0.15);
  border-radius: 25px;
}

.projectlist-details-content .content-options .content-button.search,
.userlist-details-content .content-options .content-button.search,
.organizationlist-details-content .content-options .content-button.search,
.quotelist-details-content .content-options .content-button.search {
  height: 40px;
}

.projectlist-details-content .content-options .content-button.search {
  padding: 11px 11px 11px 20px;
}

.projectlist-details-content .content-options .content-button.date,
.userlist-details-content .content-options .content-button.date,
.organizationlist-details-content .content-options .content-button.date,
.quotelist-details-content .content-options .content-button.date {
 max-height: 40px;
}

.projectlist-details-content .content-options .content-button.date .btn-date,
.userlist-details-content .content-options .content-button.date .btn-date,
.organizationlist-details-content
  .content-options
  .content-button.date
  .btn-date,
.quotelist-details-content .content-options .content-button.date .btn-date {
  border: none;
  background-color: #fff;
}

.projectlist-details-content .content-options .content-button.clear .btn-clear,
.userlist-details-content .content-options .content-button.clear .btn-clear,
.organizationlist-details-content
  .content-options
  .content-button.clear
  .btn-clear,
.quotelist-details-content .content-options .content-button.clear .btn-clear {
  border: none;
  background-color: #fff;
}

.projectlist-details-content .content-options .content-button.clear,
.userlist-details-content .content-options .content-button.clear,
.organizationlist-details-content .content-options .content-button.clear,
.quotelist-details-content .content-options .content-button.clear {
  height: 40px;
}

.projectlist-details-content .content-options .content-button select,
.quotelist-details-content .content-options .content-button select {
  border: none;
  outline: none;
  width: auto;
}

.projectlist-details-content .content-options .content-button span,
.userlist-details-content .content-options .content-button span,
.organizationlist-details-content .content-options .content-button span,
.quotelist-details-content .content-options .content-button span {
  font-family: "Satoshi Medium";
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #032020;
  opacity: 60%;
}

.projectlist-details-content .right-buttons,
.userlist-details-content .right-buttons,
.organizationlist-details-content .right-buttons,
.quotelist-details-content .right-buttons {
  display: flex;
  gap: 28px;
}

.projectlist-details-content .export .btn,
.userlist-details-content .export .btn,
.organizationlist-details-content .export .btn,
.quotelist-details-content .right-buttons .export .btn {
  padding: 7px 40px;
  border: 1px solid rgba(3, 32, 32, 0.15);
  border-radius: 25px;
  background-color: #f6f7fc;
}

.projectlist-details-content .export .btn span,
.userlist-details-content .export .btn span,
.organizationlist-details-content .export .btn span,
.quotelist-details-content .right-buttons .export .btn span {
  font-family: "Satoshi Medium";
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #032020;
  opacity: 60%;
}

.create-project-btn .btn,
.create-user-btn .btn,
.create-org-btn .btn,
.create-quote-btn .btn {
  background-color: #ff6b00;
  border-radius: 50px;
  border: none;
  height:40px;
}

.create-project-btn .btn a,
.create-user-btn .btn a,
.create-org-btn .btn a,
.create-quote-btn .btn a {
  color: #fff;
  text-decoration-line: none;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  gap: 8px;
  white-space: nowrap;
}

.search-filter-input {
  display: flex;
}

.page-no li {
  width: 24px;
}

.projectlist-details-content .search-option,
.userlist-details-content .search-option,
.organizationlist-details-content .search-option,
.quotelist-details-content .search-option {
  display: flex;
  gap: 6px;
  border: 1px solid rgba(3, 32, 32, 0.15);
  padding: 5px 5px 3px 15px;
  height: 40px !important;
  width: 100%;
  border-radius: 5px;
}

.projectlist-details-content .search-option.searchfilter,
.userlist-details-content .search-option.searchfilter,
.organizationlist-details-content .search-option.searchfilte,
.quotelist-details-content .search-option.searchfilterr {
  height: 38px;
}

.projectlist-details-content .search-option input:focus,
.userlist-details-content .search-option input:focus,
.organizationlist-details-content .search-option input:focus,
.quotelist-details-content .search-option input:focus {
  outline: none;
}

.projectlist-details-content .search-option input,
.userlist-details-content .search-option input,
.organizationlist-details-content .search-option input,
.quotelist-details-content .search-option input {
  border: none;
}

.projectlist-details-table-content .table-content .list table thead,
.userlist-details-table-content .table-content .list table thead,
.organizationlist-details-table-content .table-content .list table thead,
.quotelist-details-table-content .table-content .list table thead {
  background-color: "";
  padding: 0 24px;
}

.projectlist-details-table-content .table-content .list table thead tr,
.userlist-details-table-content .table-content .list table thead tr,
.organizationlist-details-table-content .table-content .list table thead tr,
.quotelist-details-table-content .table-content .list table thead tr {
  padding: 10px 0 !important;
  border-bottom: 1px solid rgba(3, 32, 32, 0.15);
}

.projectlist-details-table-content .table-content .list table thead tr th,
.userlist-details-table-content .table-content .list table thead tr th,
.organizationlist-details-table-content .table-content .list table thead tr th,
.quotelist-details-table-content .table-content .list table thead tr th {
  font-family: "Satoshi Medium";
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  color: #032020;
  padding: 2px 20px;
}

.projectlist-details-table-content .table-content .list table thead tr th.opac,
.userlist-details-table-content .table-content .list table thead tr th.opac,
.organizationlist-details-table-content
  .table-content
  .list
  table
  thead
  tr
  th.opac,
.quotelist-details-table-content .table-content .list table thead tr th.opac {
  opacity: 60%;
}

.projectlist-details-table-content .table-content .list table tbody tr,
.userlist-details-table-content .table-content .list table tbody tr,
.organizationlist-details-table-content .table-content .list table tbody tr,
.quotelist-details-table-content .table-content .list table tbody tr {
  border-bottom: 1px solid rgba(3, 32, 32, 0.15);
}
.projectlist-details-table-content .table-content .list table tbody tr td,
.userlist-details-table-content .table-content .list table tbody tr td,
.organizationlist-details-table-content .table-content .list table tbody tr td,
.quotelist-details-table-content .table-content .list table tbody tr td {
  font-family: "Satoshi Medium";
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  color: #032020;
  padding: 2px 20px;
}

.projectlist-details-table-content
  .table-content
  .list
  table
  thead
  tr
  th
  .form-check-input,
.organizationlist-details-table-content
  .table-content
  .list
  table
  thead
  tr
  th
  .form-check-input,
.quotelist-details-table-content
  .table-content
  .list
  table
  thead
  tr
  th
  .form-check-input {
  background-color: #032020;
  width: 20px;
  height: 20px;
  border-radius: 12px;
  margin: 0;
}

.projectlist-details-table-content
  .table-content
  .list
  table
  tbody
  tr
  td
  .form-check-input,
.userlist-details-table-content
  .table-content
  .list
  table
  tbody
  tr
  td
  .form-check-input,
.organizationlist-details-table-content
  .table-content
  .list
  table
  tbody
  tr
  td
  .form-check-input,
.quotelist-details-table-content
  .table-content
  .list
  table
  tbody
  tr
  td
  .form-check-input {
  width: 20px;
  height: 20px;
  border-radius: 12px;
  margin: 0;
}

.checked-box {
  text-align: center;
}

.action-buttons {
  display: flex;
}

.action-buttons .opac-action {
  opacity: 60%;
  background: #032020;
  border-radius: 12px;
}

.projectlist-details-table-pagination,
.userlist-details-table-pagination,
.organizationlist-details-table-pagination,
.quotelist-details-table-pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.projectlist-details-table-pagination .page-no,
.userlist-details-table-pagination .page-no,
.organizationlist-details-table-pagination .page-no,
.quotelist-details-table-pagination .page-no {
  padding-left: 24px;
}

.projectlist-details-table-pagination .page-selection,
.userlist-details-table-pagination .page-selection,
.organizationlist-details-table-pagination .page-selection,
.quotelist-details-table-pagination .page-selection {
  padding-right: 24px;
}

.projectlist-details-table-pagination .page-no ul li button,
.userlist-details-table-pagination .page-no ul li button,
.organizationlist-details-table-pagination .page-no ul li button,
.quotelist-details-table-pagination .page-no ul li button {
  height: 20px;
  width: 20px;
}

.projectlist-details-table-pagination .page-selection select,
.userlist-details-table-pagination .page-selection select,
.organizationlist-details-table-pagination .page-selection select,
.quotelist-details-table-pagination .page-selection select {
  border: none;
  border-radius: 60px;
  background-color: #f6f7fc;
  padding: 6px 16px 6px 10px;
  color: #032020;
  text-decoration-line: none;
  font-family: "Satoshi Medium";
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
}

.dateRange {
  position: absolute;
  left: 23%;
}

.DateAlignCenter {
  text-align: center !important;
}

.filterIconsMainDiv {
  img {
    height: 18px;
    object-fit: contain;
  }
  .dateicon {
    margin-top: 5px;
  }
  span {
    font-size: 14px !important;
  }
}
.checkInputProjectList,
.checkInputManageUserList,
.checkInputManageOrgList,
.checkInputQuoteView {
  top: 0 !important;
}

.projectlist-details-table-content table tr div ul li label,
.organizationlist-details-table-content table tr div ul li label,
.userlist-details-table-content table tr div ul li label,
.quotelist-details-table-content table tr div ul li label {
  display: flex;
  gap: 12px;
  color: #717171;
}

.selection span {
  font-family: "Satoshi Medium";
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  color: #032020;
  opacity: 60%;
  white-space: nowrap;
  margin: 12px 19px;
  display: block;
}
.reset-selection {
  color: #fe6b18;
  border: none;
  background: none;
  font-size: 14px;
  font-family: "Satoshi Medium";
  margin-top: 8px;
  margin-left: 7px;
  font-weight: 400;
  line-height:18.9px;
}
.reset-selection:hover{
  background: none;
}
.btn-success.reset-selection {
  --bs-btn-hover-color: inherit; 
  --bs-btn-hover-bg: inherit; 
  --bs-btn-hover-border-color: inherit; 
  color: #FF6B00; 
}
.reset-selection::after{
  content:'';
  position: absolute;
  left: 12px;
  width: 62%;
  height: 1px;
  background-color:  rgba(3, 32, 32, 0.15);
  margin-top: 22px;
}

.selectionfilter-button{
  cursor: pointer;
  border: none;
  background: none;
  position: absolute;
}
.selectionfilter-button .selection-dropdown{
  overflow: hidden;
}
.selection-li-content {
  overflow: scroll;
  height: 334px;
  background: rgba(227, 246, 244, 0.2);
  border-radius: 20px 20px 0px 0px;
  margin-top: 15px;
}

.selection-li-content::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.selection-li-content::-webkit-scrollbar-thumb {
  background: #d9d9d9;
}

.selection-li-content::-webkit-scrollbar-track {
  background: #d9d9d9;
}
.selection-li-content li input[type="checkbox"] {
  padding: 7px !important;
  height: 20px !important;
  top: 0 !important;
}
.selection-li-content li input[type="checkbox"]:checked {
  padding: 7px;
  height: 15px;
  top: 0 !important;
}

.projectlist-details-table-content .ForNameRow {
  width: 25%;
  text-align: left;
}

.projectlist-details-table-content .ForStausRow {
  width: 25%;
  text-align: center;
}

.projectlist-details-table-content .ForRoleRow {
  width: 20%;
  text-align: left;
}
.projectlist-details-table-content .ForDateRow {
  width: 15%;
  text-align: left;
}
.main-column-selection {
  right: 4px;
  z-index: 1000;
  padding: 2px 20px;
  margin-top: 4px;
}
.selection-button {
  cursor: pointer;
  border: none; 
}
.activebackgroundColor{
  background-color: #FEBF9B;
  border-radius: 10px;
  padding: 6px 4px 2px 4px;
}
.selection-button img{
  padding: 5px;
  position:relative;
  top:-3px;
}
.selection-dropdown {
  position: absolute;
  top: 100%;
  z-index: 1;
  box-sizing: border-box;
  width: 270px;
  overflow-y: auto;
  overflow-x: hidden;
}
.selection-container {
  position: relative;
  padding-top: 10px;
}
.selection-container-content {
  background-color: #fe6b18;
  border-radius: 20px 20px 0 0;
  padding: 10px;
  padding-left: 15px;
  color: #fff;
  margin-bottom: -1px;
  z-index: 2;
  display: flex;
  justify-content: space-between;
}
.selection-container-content label{
  padding:0 0 8px 0;
  font-family: "Satoshi Medium";
  font-weight: 400;
  font-size:16px;
  line-height: 21.6px;
}
.selection-container-content  img{
  position: relative;
  top: 10px;
  width: 19px;
  height: 19px;
}

.selection-container ul {
  list-style-type: none;
  padding: 0px;
  margin: 0;
  border-radius: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  position: relative;
  top: -12px;
  z-index: 2;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.selection-li-content li {
  padding: 6px;
}
.selection-li-content li img {
  margin-right: "5px";
}

.list-sub-accordian .inner-table img.babyModel-image-list-img {
  width: 30px;
  height: 30px;
}

.building-details-actions img {
  width: 15px;
  height: 15px;
}
@media only screen and (max-width: 768px) {
  .projectlist-details .form-head.list-project h2,
  .userlist-details .form-head.list-user h2,
  .quotelist-details .form-head.list-quote h2 {
    font-size: 22px;
  }
  .projectlist-details-content {
    overflow-x: scroll;
    gap: 12px;
  }
  
  .projectlist-details-table-content,
  .userlist-details-table-content,
  .organizationlist-details-table-content,
  .quotelist-details-table-content {
    width: 100%;
    overflow-x: scroll;
  }
  .projectlist-record .projectlist-details .form-head,
  .userlist-record .userlist-details .form-head,
  .organizationlist-record .organizationlist-details .form-head,
  .quotelist-record .quotelist-details .form-head {
    padding: 0 11px;
  }
  .projectlist-details-table-pagination .page-no,
  .userlist-details-table-pagination .page-no,
  .organizationlist-details-table-pagination .page-no,
  .quotelist-details-table-pagination .page-no {
    padding-left: 11px;
  }
  .projectlist-details-table-pagination .page-selection,
  .userlist-details-table-pagination .page-selection,
  .organizationlist-details-table-pagination .page-selection,
  .quotelist-details-table-pagination .page-selection {
    padding-right: 11px;
  }
  .search-input {
    display: flex;
  }
  .create-project-btn .btn a span,
  .create-user-btn .btn a span,
  .create-org-btn .btn a span,
  .create-quote-btn .btn a span {
    font-size: 10px;
  }
}

@media only screen and (min-width: 2000px) and (max-width: 3000px) {
  .create-project-btn .btn,
  .create-user-btn .btn,
  .create-org-btn .btn,
  .create-quote-btn .btn {
    height: 40px;
  }
  .create-project-btn .btn a,
  .create-user-btn .btn a,
  .create-org-btn .btn a,
  .create-quote-btn .btn a {
    font-size: 10px;
    align-items: center;
  }
  .projectlist-details-content .content-options .content-button.date,
  .userlist-details-content .content-options .content-button.date,
  .organizationlist-details-content .content-options .content-button.date,
  .quotelist-details-content .content-options .content-button.date {
    max-height: 40px;
  }
  .projectlist-details-content,
  .userlist-details-content,
  .organizationlist-details-content,
  .quotelist-details-content {
    overflow-x: scroll;
  }
  .projectlist-details-table-content,
  .userlist-details-table-content,
  .organizationlist-details-table-content,
  .quotelist-details-table-content {
    overflow-x: scroll;
  }
}

.clicked-row {
  background-color: #d3d3d3;
}

.projectlist-details-table-content
  .table-content
  .list
  table
  tbody
  .dropDownRow
  td
  table
  tr {
  border-bottom: 2px solid #fff;
  background-color: #f2f2f2;
}

.projectlist-details-table-content
  .table-content
  .list
  table
  tbody
  .dropDownRow
  td {
  padding: 0;
  width:12.11%;
  border-radius: 12px;
}
.subParentDiv2 .projectlist-details-table-content .table-content .list table tbody .dropDownRow td {
   padding:0;
}
.list-sub-accordian {
  padding: 0;
  border: none;
}
.list-sub-accordian-row .list-sub-accordian td .row-wrapper table tbody tr td{
  padding:2px 20px!important;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
  max-width: 150px;
}


.row-wrapper {
  border-radius: 12px;
  overflow: hidden;
  background-color: #f2f2f2;
}

.inner-table {
  width: 100%;
  border-collapse: collapse;
}

.downImg {
  width: 20px;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.rotated {
  transform: rotate(180deg);
}

.inner-table tr {
  border-bottom: none !important;
}

.inner-table td {
  padding: 10px;
  border: none;
}

.inner-table td a img {
  display: inline-block;
  vertical-align: middle;
}

.dropdownBtn {
  border: none;
}

.columnItemStyle {
  display: flex;
  justify-content: center;
}

.dropdownBtn {
  position: relative;
  background:none;
}
.addressListColumn .selection-li-content li label{
  display: flex;
  gap: 12px;
  color: #717171;
  padding:10px;
}

.dropdown-content {
  display: flex;
  padding: 25px 0;
  border: 1px solid #ddd;
  border-radius: 24px;
  background-color: white;
  margin:14px 14px;
}
.building-documents{
  width: 50%;
  padding: 0 15px;
}
.building-details-heading {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  gap: 12px;
  width: 100%;
  align-items: center;
  }

.building-details-heading h2 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
}
.building-details-heading img {
  width: 11px;
  height: 14px;
}

.building-details-img,
.building-details-sub-data,
.building-details-actions {
  display: flex;
  flex-direction: column;
}
.building-details-sub-data h5 {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0;
  font-family: "Satoshi Medium";
  line-height: 16.8px;
  letter-spacing: 0em;
  text-align: left;
  color: #302030;
  white-space: normal;
  min-width: 120px;
  max-width:120px;
  word-wrap: break-word;
  word-break: break-word;
}

.building-details-sub-data p {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0;
  white-space: normal;
}
.building-details-sub-data .data p{
  font-weight: 400;
}

.building-details-data {
  display: flex;
  margin: 28px 20px 0 20px!important;
  gap:3%;
}

.building-details-img {
  display: flex;
  border-radius: 50px;
}

.building-details-actions {
  display: flex;
  flex-direction: column;
  gap: 14px;
  width:100%;
}

.building-details-img .babyModalImg {
  border-radius: 70px;
  height: 116.17px;
  width: 117px;
  margin: 0;
  shape-outside: circle();
  clip-path: circle();
}
.street-post{
  margin-top:9px;
}
.panid{
  margin-top:10px;
}
.building-details .babyModalImg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 20px;
  border: 2px solid black;
}

.building-details div {
  flex-grow: 1;
}

.building-details .actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.building-details .actions button {
  border: 1px solid #ddd;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  height: 35px;
  width: 200px;
  border-radius: 5px;
}

.address-documents {
  width: 50%;
  padding: 0 10px 0 15px;
  border-left: 2px solid #fe6b18;
}

.address-table-content{
  margin:0px 0px 0 20px;
}
.address-details {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  gap: 12px;
  width: 100%;
  align-items: center;
}

.address-details img {
  width: 11px;
  height: 14px;
}

.address-details h2 {
  font-family: "Satoshi Medium";
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
  color: #302030;
  margin-bottom: 0;
}

.centered-content {
  display: flex;
  gap: 12px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.centered-content h2 {
  font-family: "Satoshi Medium";
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0em;
  margin-bottom: 0;
  color: #302030;
}

.centered-content img {
  width: 11px;
  height: 14px;
}


.address-documents .address-table-content .addressListTableDiv table thead {
  display: table;
  width: 100%;
}

.address-documents .address-table-content .addressListTableDiv table thead tr,
.address-documents .address-table-content .addressListTableDiv table tr {
  background: none !important;
}

.address-documents .address-table-content .addressListTableDiv table tbody {
  display: block;
  max-height: 175px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #d9d9d9 !important;
  padding-right: 10px; /* Add padding to the right side */
  box-sizing: content-box;
}

.address-documents .address-table-content .addressListTableDiv table tbody tr {
  border: 2px solid #f2f2f2 !important;
  border-radius: 9px;
  display: table;
  padding: 2px 9px !important;
  width: 100%;
}
/* .address-documents .address-table-content .addressListTableDiv table thead th, .address-documents .address-table-content .addressListTableDiv table tbody td{
  width: 16.66% !important; 

} */

.documents {
  display: flex;
  flex-direction: column;
  margin-left: 80px;
}

.document-icons {
  display: flex;
  gap: 10px;
}

.addressListTableDiv {
  position: relative;
}

.address-report {
  position: absolute;
  right: 0px;
  top: 31px;
  text-align: right;
  cursor: pointer;
  visibility: visible;
  padding: 0 !important;
  height: 0;
  opacity: 1;
}

.babyModel-column{
  width: 2%!important;
}

.address-documents:has(.addressListTableDiv table tbody:empty) .address-report {
  display: none; /* Hides the element when tbody is empty */
}

.view-report {
  width: 11px;
  height: auto;
  cursor: pointer;
  position: absolute;
  right: 0;
  top:-16px
}

.address-documents .address-table-content .addressListTableDiv table tbody tr:nth-of-type(even) {
  background-color: #f2f2f2 !important;
}

.document-icons img {
  width: 30px;
  height: 30px;
  cursor: pointer;
}


.building-record{
  display: flex;
  gap: 18px;
}
.building-details-img {
  display: flex;
  border-radius: 50px;
}


.actionsBtn1 {
  background-color: #fe6b18;
  color: white;
}
.actionsBtn2 {
  background-color: #004747;
  color: white;
}
.actionsBtn3 {
  background-color: #FE6B18;
  color: white;
}
.mjop{
  background-color: lightgray;
}
.mjop,.analysis{
  color: white;
  padding: 7px 10px;
  border-radius: 12px;
  border: none;
  cursor: not-allowed;
  pointer-events: none;
  margin-right:35px;
  width:55%;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  height:27.38px;
}

.mjopData a, .analysis a{
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  align-items: center;
  text-decoration: none;
  color: #fff !important;
}
.defaultGrayColor{
  background-color: lightgray;
  color: white;
  padding: 7px 10px;
  border-radius: 12px;
  border: none;
  width: 55%;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  height:27.38px;
}
.defaultNotAdmin{
  background-color: lightgray;
  color: white;
  padding: 7px 10px;
  border-radius: 12px;
  font-size: 12px;
  border: none;
  font-weight: 700;
  width: 55%;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin-right: 35px;
  height:27.38px;
}

.nen{
  background-color: #004747;
}

.mjoplist{
  background-color: #45C0B2;
}

.exportlist{
  background-color: #CF8E00;
}



.building-details-actions .actionsBtn1,
.building-details-actions .actionsBtn2,
.building-details-actions .actionsBtn3 {
  color: white;
  padding: 7px 10px;
  border-radius: 12px;
  border: none;
  width: 55%;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  height:27.38px;
}
.building-details-actions button img{
  width:15px;
  height:15px;
}

.building-details-actions .actionsBtn1 {
  background-color: #fe6b18;
}

.building-details-actions .actionsBtn2 {
  background-color: #004747;
}

.selection-dropdown {
  position: absolute;
  top: 100%;
  z-index: 1000;
  white-space: nowrap;
  max-width: 300px;
  overflow-x: hidden;
}

/* Adjust the dropdown position if it overflows the viewport */
.selection-dropdown {
  right: 0;
  left: auto;
  transform: translateX(0);
}

.inner-table td {
  width: 10.71%; /* Assuming 8 columns */
  padding: 8px;
  text-align: left;
  vertical-align: middle;
  box-sizing: border-box;
  border: 1px solid transparent; /* Uniform border to avoid size differences */
}

.addressListTableDiv table tbody tr {
  padding: 8px !important; /* Apply padding to the entire <tr> */
  box-sizing: border-box; /* Ensure padding is included within the width of the row */
}

.addressListTableDiv th,
.addressListTableDiv td {
  box-sizing: border-box;
}

.addressListTableDivTr .first {
  width: 12%!important;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
  max-width: 150px;
}
.addressListTableDivTr .second {
  width: 20%!important;
}
.addressListTableDivTr .third {
  width: 10%!important;
}
.addressListTableDivTr .fourth {
  width: 10%!important;
}
.addressListTableDivTr .fifth {
  width: 10%!important;
}
.addressListTableDivTr .sixth {
  width: 9%!important;
}
.addressListTableDivTr .third img {
  position: relative;
  left:-5px;
}
.addressListTableDivTr .fourth img{
  position: relative;
  left:-10px;
}
.addressListTableDivTr .fifth img{
  position: relative;
  left:-17px;
}

.addressListTableDivTr .sixth img{
  position: relative;
  left:-22px;
}
/* .addressListTableDiv table thead tr th {
  padding: 4px 12px !important;
} */
.addressListTableDiv table thead th:last-child,
.addressListTableDiv table tbody td:last-child {
  white-space: nowrap; /* Prevent wrapping in the last column header */
}
.addressListTableDiv table tbody tr {
  padding: 4px 12px !important; /* Apply padding to the rows within tbody */
}

.addressListTableDiv tbody tr {
  margin: 4px 0; /* Margin around each row */
}

.addressListTableDiv tbody td {
  padding: 4px 12px !important; /* Padding inside each cell */
}

.addressListTableDiv table thead tr th {
  text-align: center !important;
  padding:0!important;
}
.addressListTableDiv tbody tr td {
  text-align: center;
  white-space: nowrap;
}

.informationButtons{
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.buttonRow{
  display: flex;
  justify-content:end;
  gap:6px;
}
.buttonRow .btn.btn-primary{
  background:none;
  padding:2px 4px;
  position: relative;
  top: -1px;
}

.addressListTableDivTr .btn.btn-primary{
  background:none;
}
.last-column{
  width:3%;
}

.buttonInformation {
  margin-left: 5px;
  border-radius: 17px;
  border: none;
  height: 25px;
  background:none;

}
.buidingButtonsInformation{
  border-radius: 17px;
  border: none;
  height: 38px;
  background:none;
}
.overlay-image {
  position: relative;
}
.pointer {
  cursor: pointer;
}
.floor-overlay{
 margin-right: -16px;
}
.informationButtons {
  position: relative; /* Relative positioning to place the tooltip correctly */
  margin-bottom:35px;
}
.buttonInformation img{
  width:25px;
  height:25px;
}

.informationButtons.tooltip{
  position: absolute;
  background-color: #fff;
  border: 2px solid #ccc;
  padding: 8px;
  top: 0 !important;;
  left: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 35%;
  margin-top:12px;
}

.exploreButton{
  color: white;
  padding: 7px 10px;
  border-radius: 12px;
  border: none;
  display: flex;
  background-color: #FE6B18;
  gap: 10px;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}
.exploreInActive{
  color: white;
  border-radius:12px;
  padding: 7px 10px;
  cursor: not-allowed; 
  pointer-events: none;
  display: flex;
  gap:10px;
  justify-content: center;
  border:none;
  white-space: nowrap;
  background-color: #d3d3d3;

}
.viewExploreButton{
  color: white;
  padding: 3px 10px 6px 10px;
  border-radius: 12px;
  border: none;
  background-color: #FE6B18;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}
.defaultExploreButton{
  color: white;
  padding: 3px 10px 6px 10px;
  border-radius: 12px;
  border: none;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  background-color: #d3d3d3;
  pointer-events: none;
}

.exploreButton a, .actionsBtn3 a, .analysis a , .mjop a,.defaultGrayColor a, .viewExploreButton a, .defaultExploreButton a,.exploreInActive a,.analysis-btn a{
  font-family: "Satoshi Medium";
  font-weight:700;
  font-size:12px;
  line-height:18px;
  align-items: center;
  text-decoration: none;
  color:#fff!important;
}
.actionsBtn3 {
  color: white;
  border-radius:12px;
  padding: 7px 10px;
  cursor: not-allowed; /* Default cursor when disabled */
  pointer-events: none;
  display: flex;
  gap:10px;
  justify-content: center;
  border:none;
  white-space: nowrap;
}

.actionsBtn3.active {
  cursor: pointer; /* Cursor when active */
  pointer-events: auto; /* Make the button clickable */
}

.informationButtons button, .addressListTableDivTr td button{
  border:none;
}

.btn.btn-primary {
  --bs-btn-hover-bg: transparent;
  border:none;
  --bs-btn-active-bg:#FE6B18;
}

.addressListTableDivTr td button img{
  width:20px;
  height:20px;
}

.exploreButton1 {
  color: white;
  padding: 10px 8px;
  border-radius: 12px;
  border: none;
  display: flex;
  background-color: #FE6B18;
  gap: 10px;
  white-space: nowrap;
}

.analysis-btn {
  padding: 7px 10px;
  border: none;
  cursor: pointer;
  width: 55%;
  border-radius:12px;
  display: flex;
  gap:10px;
  margin-right: 35px;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.report-btn {
  padding: 7px 10px;
  border: none;
  cursor: pointer;
  width: 55%;
  border-radius:12px;
  display: flex;
  gap:10px;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.analysis-btn.red {
  background-color: #e57373;
  color: white;
}

.analysis-btn.green {
  background-color: #81c784;
  color: white;
}

.analysis-btn.orange {
  background-color: #ffb74d;
  color: white;
}

.analysis-btn.yellow {
  background-color: #fff176;
  color: black;
}

.analysis-btn.gray {
  background-color: lightgray;
  color: black;
}

.analysis-btn:hover {
  filter: brightness(0.9);
}

.json-report{
  display: flex;;
  justify-content: space-between;
}
.json-report .building-details-block .building-details-heading h2{
  font-size: 15px!important;
  padding:0;
}
.building-details-heading h2{
  font-size: 15px!important;
  padding:0!important;
}
.json-report .building-details-sub-data{
 display:flex;
 flex-direction: row;
 gap:70px;
}
.json-export-btn{
  background-color: #CF8E00;
  border-radius: 50px;
  border: none;
  height: 40px;
}
.json-export-btn a{
  color:#fff;
  text-decoration: none;
}
.json-filter-btn{
  background-color: #45C0B2;
  padding: 7px 40px;
  border: 1px solid rgba(3, 32, 32, 0.15);
  border-radius: 25px;
}
.json-filter-btn span{
  color:#fff;
}

.json-report .building-details-data{
  margin-top:0;
  margin-bottom:12px;
}
.json-content .projectlist-record .projectlist-details .form-head{
  display: block;
}
.json-header .pageHeader{
  display: flex;
  justify-content: flex-start;
  gap: 16px;
}
.json-header .pageHeader span{
  font-size: 23px;
  margin-top: 16px;
}
.json-report .building-details-sub-data h5{
  white-space: nowrap;
  font-size: 14px;;

}
.report-details-heading{
  display:flex;
  gap: 12px;
  align-items: center;
}
.report-details-heading img{
  width:12px;
  height:12px;
}
.report-details{
  display:flex;
  gap:24px;
}
.refresh-details-content img{
  background-color: #f2f2f2;
  padding:15px;
  height:93px;
  border-radius: 12px;;
}
.report-details-content-sub-data{
  display: flex;
  gap:55px;
  background-color: #F2f2F2;
  padding:15px;
  border-radius:12px
}
.report-details-content-sub-data p{
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0;
  white-space: nowrap;
}

.report-details-content-sub-data .data p{
  font-weight: 400;
}
.report-details-heading h2 {
  font-weight: 500;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
    color: #302030;
    font-size:15px!important;
    padding:0!important;
    margin:0
}
.reportbuilding-details-sub-data h5{
  font-size:14px;
  margin-bottom:0
}
.reportbuilding-details-sub-data p{
  margin-bottom:0
}
.reportbuilding-details-sub-data{
  background-color: #F2F2F2;
  display:flex;
  gap:24px;
  padding:12px 12px;
  border-radius:12px;
}
.json-report .building-details-block{
 width:fit-content;
}
.json-report .building-details-data {
  gap:20px;
  margin-top:20px
}
.json.main-column-selection {
  right:-13px;
  top:-2px;
}

@media (max-width: 1600px) {
  .projectlist-details-content,
  .userlist-details-content,
  .organizationlist-details-content,
  .quotelist-details-content, 
  .projectlist-details-table-content,
  .userlist-details-table-content,
  .organizationlist-details-table-content,
  .quotelist-details-table-content,.check-options.inside-service,.json-report {
    overflow-x: scroll; /* No overflow at 100% scaling */
  }
  .main-outside-content .outside-model .left-content .model .check{
    width:100%;
  }
  .quote-option-record .tab-content.record .check-options.purpose{
    overflow-x:scroll;
  }
  .quote-option-record .tab-content.record .check-options.purpose .check label{
    font-size: 11px;
  }
 .quote-outside-extras-record .tab-content.record .check-options.extras  .check{
  padding: 11px 5px 11px 5px;
 }
 .quote-outside-extras-record .tab-content.record .check-options.extras  .check p{
  padding: 16px 5px 16px 5px;
  font-size: 12px;
 }
  .addressDiv-content{
    overflow-x: scroll;
  }
.analysis{
  width:85%!important;
}
.building-details-actions .actionsBtn3,.defaultGrayColor,.analysis-btn,.defaultNotAdmin{
  width:85%!important;
}
.building-details-data{
  margin-top:49px!important;
}
.bulk-address-data-table{
  overflow-x: scroll;
}
.addressListTableDivTr .third img {
  position: relative;
  left:-1px;
}
.addressListTableDivTr .fourth img{
  position: relative;
  left:8px;
}
.addressListTableDivTr .fifth img{
  position: relative;
  left:-3px;
}

.addressListTableDivTr .sixth img{
  position: relative;
  left:-12px;
}
}


/* 14-inch MacBook Pro (3024 × 1964) */
@media only screen and (min-width: 3024px) and (max-width: 3024px) and 
(min-height: 1964px) and (max-height: 1964px) {
  .addressListTableDivTr .first {
    width: 30%!important;
  }
  .addressListTableDivTr .second {
    width: 20%!important;
  }
  .addressListTableDivTr .third {
    width: 20%!important;
  }
  .addressListTableDivTr .fourth {
    width: 10%!important;
  }
  .addressListTableDivTr .fifth {
    width: 10%!important;
  }
  .addressListTableDivTr .sixth {
    width: 10%!important;
  }
  .building-details-data{
    gap: 1% !important;
    margin-top:50px!important;
  }
  .building-details-data{
    margin-top:28px!important;
  }
  .analysis-btn{
    white-space: nowrap!important;
  }
 
}


 @media screen and (max-width: 1024px) and (max-height: 768px){
  .building-details-actions .actionsBtn3,
  .defaultGrayColor,
  .analysis {
    width: 100%;
  }
  .building-details-sub-data p{
    white-space:normal;
  }
  .building-details-sub-data p,.building-details-sub-data h5{
    font-size: 9px;
   }
   .building-details-img .babyModalImg{
    height:117px;
   }
   .building-details-data{
    gap: 0% !important;
    margin:28px 0px 0 20px!important;
   }
   
   .informationButtons{
    margin-bottom:80px;
   }
   .building-record{
    gap:18px;
   }
   .building-details-block {
    width: 50%;
    padding: 0 0;
  }
}

@media only screen and (max-width: 768px) {
  .building-details-actions .actionsBtn3,.defaultGrayColor,.analysis{
   width:100%;
   }
   .building-details-sub-data p,.building-details-sub-data h5{
    font-size: 9px;
   }
   .building-details-img .babyModalImg{
    height:117px;
   }
 }
 @media screen and (max-width: 1024px) and (max-height: 768px) {
  .building-details-actions .actionsBtn3,
  .defaultGrayColor,
  .analysis {
    width: 100%;
  }
  .building-details-sub-data p{
    white-space:normal;
  }
  .building-details-sub-data p,.building-details-sub-data h5{
    font-size: 9px;
   }
   .building-details-img .babyModalImg{
    height:117px;
   }
   .building-details-data{
    margin-top:49px!important;
  }
}
@media screen and (min-width: 2560px) {
  .addressListTableDivTr .third img {
    position: relative;
    left:-18px;
  }
  .addressListTableDivTr .fourth img{
    position: relative;
    left:-24px;
  }
  .addressListTableDivTr .fifth img{
    position: relative;
    left:-38px;
  }
  
  .addressListTableDivTr .sixth img{
    position: relative;
    left:-47px;
  }
}

@media screen and (min-width: 3840px) {
  .addressListTableDivTr .third img {
    position: relative;
    left:-29px;
  }
  .addressListTableDivTr .fourth img{
    position: relative;
    left:-40px;
  }
  .addressListTableDivTr .fifth img{
    position: relative;
    left:-62px;
  }
  
  .addressListTableDivTr .sixth img{
    position: relative;
    left:-79px;
  }
}

@media screen and (min-width: 5120px) {
  .addressListTableDivTr .third img {
    position: relative;
    left:-41px;
  }
  .addressListTableDivTr .fourth img{
    position: relative;
    left:-59px;
  }
  .addressListTableDivTr .fifth img{
    position: relative;
    left:-87px;
  }
  
  .addressListTableDivTr .sixth img{
    position: relative;
    left:-108px;
  }
}

