/* list user */

.rdrDateRangePickerWrapper {
  position: absolute;
  z-index: 1;
}

.userlist-details-content .content-options .content-button select {
  border: none;
  outline: none;
  width: 74px;
}

.userlist-details-content .content-options .content-button.search {
  padding: 11px 11px 11px 20px;
}

.search-input {
  display: flex;
}

table {
  width: 100%;
  border-collapse: collapse;
}

.userlist-details-content .user-select {
  border: none;
}

.checkInputManageUserList {
  top: 0 !important;
}

.userlist-details-table-content .ForNameRow {
  width: 20%;
  text-align: left;
}

.userlist-details-table-content .ForEmailRow {
  width: 15%;
  text-align: left;
}
.userlist-details-table-content .ForRoleRow {
  width: 15%;
  text-align: left;
}
.userlist-details-table-content .ForDateRow {
  width: 15%;
  text-align: left;
}
.userlist-details-table-content .FororganizationRow {
  width: 10%;
  text-align: left;
}
.userlist-details-table-content .ForStatusRow {
  width: 10%;
  text-align: left;
}

@media only screen and (max-width: 768px) {
  .create-user-btn .btn a {
    font-size: 11px;
  }
  .userlist-details-content {
    overflow-x: scroll;
  }
}
.switch {
  display: inline-block;
  position: relative;
  padding: 10%;
  margin-top: 10%;
}
